<template>
  <div>
    <div class="card p-2">
      <div>
        <h3 class="font-weight-bolder text-center">
          จัดการงวดล็อตเตอรี่
        </h3>
      </div>
      <div class="row">
        <div class="col-2" />
        <div class="col-8">
          <div>
            <label for="example-datepicker">เลือกงวดวันที่</label>
            <b-form-datepicker
              id="example-datepicker"
              v-model="round"
              class="mb-1"
            />
          </div>
          <div>
            <label for="lotto">งวดที่</label>
            <input
              id="lotto"
              v-model="round_no"
              type="number"
              class="form-control"
              placeholder="กรอกเลขงวด"
            >
          </div>
          <div>
            <label for="lotto">รูปสลาก</label>
            <div>
              <b-form-file
                v-model="file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @input="uploadImg(file)"
              />
            </div>
          </div>

          <div class="mt-2">
            <button
              class="btn btn-gradient-primary btn-block"
              @click="onSubmit()"
            >
              เปิดงวด
            </button>
          </div>
        </div>
        <div class="col-2" />
      </div>
    </div>

    <div class="card">
      <b-table
        hover
        show-empty
        responsive
        :items="items"
        :fields="fields"
        class="mt-1"
      >
        <template #cell(id)="data">
          <router-link :to="{ name: '' }">
            {{ data.item.id }}
          </router-link>
        </template>
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>

        <template #cell(depfirst_amount)="data">
          {{ data.item.depfirst_amount ? data.item.depfirst_amount : "0.00" }}
        </template>
        <template #cell(created_at)="data">
          ( {{ Time(data.item.created_at) }} ) {{ data.item.created_at }}
        </template>

        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>

        <template #cell(status)="data">
          <span
            v-if="data.item.status === 'false'"
            class="badge badge-success badge-circle"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-25"
            />
            <span>เปิดรอบอยู่</span>
          </span>

          <span
            v-else
            class="badge badge-danger badge-circle"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>ปิดรอบแล้ว</span>
          </span>
        </template>
        <template #cell(percent)="data">
          <div
            v-if="data.item.status === 'false' && percent > 0"
            class="d-flex align-items-center"
          >
            <span
              v-if="(percent / 10000) < 100"
              class="mr-1"
              style="color:red"
            >{{ (percent / 10000).toFixed(0) }}%</span>
            <span
              v-else
              class="mr-1"
              style="color:green"
            >{{ (percent / 10000).toFixed(0) }}%</span>
          </div>
        </template>
        <template #cell(action)="data">
          <div class="d-flex align-items-center">
            <b-button
              v-if="!data.item.checkreward && data.item.status === 'false'"
              variant="primary"
              class="mr-1"
              size="sm"
              @click="checkreward(data.item.round)"
            >
              เช็ครางวัล
            </b-button>
            <b-button
              v-else-if="data.item.status === 'false'"
              variant="primary"
              class="mr-1"
              size="sm"
              @click="Submit()"
            >
              ออกผล
            </b-button>
          </div>
        </template>
      </b-table>
      <b-card-body
        class="d-flex justify-content-between flex-wrap pt-0 pl-1 pb-1"
      >
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetList()"
          />
        </b-form-group>

        <!-- pagination -->
        <div class="pr-1 pt-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getList()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </div>
  </div>
</template>

<script>
import {
  BFormDatepicker, BTable, BCardBody, BPagination, BFormGroup, BFormSelect, BButton, BFormFile,
} from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  name: 'Reward',
  components: {
    BFormDatepicker,
    BTable,
    BCardBody,
    BPagination,
    BFormGroup,
    BFormSelect,
    BButton,
    BFormFile,
  },
  data() {
    return {
      lotto: '',
      list: [],
      round: null,
      fields: [
        { key: 'index', label: 'ลำดับ' },
        { key: 'status', label: 'สถานะ' },
        { key: 'roundth', label: 'ประจำวันที่' },
        { key: 'series_no', label: 'งวดที่' },
        {
          key: 'closedate',
          label: 'ปิดงวดเวลา',
          formatter: value => moment(value)
            .tz('Asia/Seoul')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'percent', label: 'เสร็จไปแล้ว' },
        { key: 'action', label: 'Action' },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      show: false,
      check: null,
      percent: 0,
      round_no: null,
      img: '',
    }
  },
  created() {
    this.interval = setInterval(() => {
      // this.getUserslist()
      this.GetPercent()
    }, 60000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  mounted() {
    this.getList()
    this.GetPercent()
  },
  methods: {
    uploadImg(file) {
      // แปลงไฟล์รูปเป็น base64
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.img = reader.result
        // บีบอัดรูปให้เล็กลง
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const image = new Image()
        image.src = this.img
        image.onload = () => {
          canvas.width = image.width
          canvas.height = image.height
          ctx.drawImage(image, 0, 0, image.width, image.height)
          const dataUrl = canvas.toDataURL('image/jpeg')
          this.img = dataUrl
          console.log(dataUrl)
        }
      }
    },
    checkreward(round) {
      const Obj = {
        round,
      }
      this.$http
        .post('/lottery/checkreward', Obj)
        .then(response => {
          this.getList()
          // console.log(response.data)
          if (response.data.status === false) {
            this.$swal({
              icon: 'warning',
              title: '<h3 style="color: #141414">แจ้งเตือน</h3>',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          } else {
            this.$swal({
              icon: 'success',
              title: '<h3 style="color: #141414">แจ้งเตือน</h3>',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }
          // this.items = response.data.RoundData
        })
        .catch(error => console.log(error))
    },
    Submit() {
      this.$swal({
        title: 'แจ้งเตือน ?',
        text: 'ยืนยันที่จะออกผลรางวัลหรือไม่ ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.show = true
          this.$http
            .get(
              '/lotto/StartBot',
            )
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.getList()
              this.show = false
              this.$swal({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'ระบบกำลังตรวจสอบผลรางวัล',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        } else {
          this.show = false
          this.$swal('ยกเลิก', 'ยกเลิกการออกผลรางวัล !!', 'error')
        }
      })
    },
    GetPercent() {
      this.$http
        .post('/lottery/GetRoundOpen_percent')
        .then(response => {
          this.show = false
          this.percent = response.data
          // console.log(response.data)
          // this.items = response.data.RoundData
        })
        .catch(error => console.log(error))
    },
    onSubmit() {
      if (!this.round) {
        this.$swal('กรุณาเลือกวันที่')
        return
      }
      if (!this.round_no) {
        this.$swal('กรุณากรอกเลขงวด')
        return
      }
      if (!this.img) {
        this.$swal('กรุณาเลือกรูปสลาก')
        return
      }
      this.submit()
    },
    submit() {
      if (this.round) {
        this.$swal({
          title: 'แจ้งเตือน ?',
          text: 'ยืนยันที่จะเปิดรอบหรือไม่ ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.show = true
            const Obj = {
              // eslint-disable-next-line no-underscore-dangle
              round: this.round,
              round_no: this.round_no,
              closedate: `${this.round} 13:30:00`,
              img: this.img,
            }
            // console.log(Obj)
            this.$http
              .post(
                '/lottery/openround', Obj,
              )
              .then(response => {
                this.getList()
                if (response.data.status === true) {
                  this.show = false
                  this.round = null
                  this.$swal({
                    icon: 'success',
                    title: 'สำเร็จ',
                    text: 'เปิดรอบสำเร็จ',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                } else {
                  this.show = false
                  this.round = null
                  this.$swal({
                    icon: 'warning',
                    title: 'เปิดรอบไม่สำเร็จ',
                    text: response.data.message,
                    customClass: {
                      confirmButton: 'btn btn-danger',
                    },
                  })
                }
              })
              .catch(error => console.log(error))
          } else {
            this.show = false
            this.$swal('ยกเลิก', 'ยกเลิกการเปิดรอบ!', 'error')
          }
        })
      }
    },
    getList() {
      const params = {
        page: this.currentPage,
      }
      this.$http
        .post('/lottery/GetRoundOpen', params)
        .then(response => {
          this.show = false
          this.items = response.data.RoundData
          this.totalRows = response.data.total
          this.check = response.data.statuscheck
        })
        .catch(error => console.log(error))
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'warning',
        title: '<h3 style="color: #141414">แจ้งเตือนจากระบบ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.table {
    border-spacing: 0 15px;
    border-collapse: separate;
}
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    scrollbar-width: none;
}
table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
}
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.table thead tr th, .table thead tr td, .table tbody tr th, .table tbody tr td {
    vertical-align: middle;
    border: none;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}
.table td, .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
th {
    text-align: inherit;
}
tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}
.table tbody tr {
    box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
    border-radius: 5px;
}
.table tbody tr td:nth-child(1) {
    border-radius: 5px 0 0 5px;
}
.table tbody tr td {
    background: #fff;
}
.table thead tr th, .table thead tr td, .table tbody tr th, .table tbody tr td {
    vertical-align: middle;
    border: none;
}
</style>
